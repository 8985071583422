<template>
  <b-card>
    <h4 class="mb-2">
      {{ $t("Project Settings") }}
    </h4>
    <!-- Cloud Service Settings -->
    <b-overlay :show="isLoading">
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <feather-icon
            class="text-primary"
            icon="UploadCloudIcon"
            size="48"
          />
          <div
            class="ml-1 cursor-pointer"
            @click="enableCloudService = !enableCloudService"
          >
            <h4 class="mb-0">
              {{ $t("cloud.enable") }}
            </h4>
            <small>
              {{ $t("cloud.share") }}
            </small>
          </div>
        </div>

        <custom-switch
          v-model="enableCloudService"
          :scale="1.5"
          :initial-value="enableCloudService"
        />
      </div>

      <b-button
        class="float-right mt-4"
        variant="primary"
        :disabled="isLoading"
        @click="saveProjectSettings"
      >
        {{ $t("Save") }}
      </b-button>
    </b-overlay>
  </b-card>
</template>

<script>
import { BCard, BButton, BOverlay } from 'bootstrap-vue'
import CustomSwitch from '@/views/common/components/CustomSwitch.vue'
import useApollo from '@/plugins/graphql/useApollo'

export default {
  components: {
    BCard,
    BButton,
    CustomSwitch,
    BOverlay,
  },
  data() {
    return {
      enableCloudService: false,
      isLoading: false,
    }
  },
  computed: {
    company() { return this.$store.state.project.selectedCompany },
    project() { return this.$store.state.project.selectedProject },
  },
  mounted() {
    this.getProjectSettings()
  },
  methods: {
    getProjectSettings() {
      this.isLoading = true
      useApollo.company.getProjectSettings({ project: this.project }).then(response => {
        this.enableCloudService = response.data.project.settings[0].information.enableCloudService
      }).finally(() => {
        this.isLoading = false
      })
    },
    saveProjectSettings() {
      this.isLoading = true
      const params = {
        companyUid: this.company,
        projectUid: this.project,
        input: {
          enableCloudServices: this.enableCloudService,
        },
      }

      useApollo.company.updateProjectCloudSettings(params).then(response => {
        this.showSuccess(response.data.updateProjectCloudSettings.message)
        this.$store.dispatch('project/setProjectSettings', {
          enableCloudService: this.enableCloudService,
        })
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>
