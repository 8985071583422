<template>
  <div>
    <b-tabs
      nav-class="nav-left"
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      nav-wrapper-class="col-md-3 col-12"
      pills
      vertical
    >
      <b-tab>
        <template #title>
          <span class="font-weight-bold">
            <feather-icon
              icon="SettingsIcon"
            />
            General Settings
          </span>
        </template>

        <general-settings />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import GeneralSettings from './components/GeneralSettings.vue'

export default {
  components: {
    BTabs,
    BTab,
    GeneralSettings,
  },
}
</script>
